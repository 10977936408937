.cf-brands-container {
	display: flex;
	flex-wrap: wrap;
	height: 200px;
	padding-top: 20px;
	overflow: scroll;
	overflow-x: hidden;
}

.cf-brands-container::-webkit-scrollbar {
  width: 10px;
}

.cf-brands-container::-webkit-scrollbar-track {
  background: #fce1c2;
}

.cf-brands-container::-webkit-scrollbar-thumb {
  background: #f29d35;
}

.cf-brand-logo-wrapper {
	display: flex;
	margin: 10px 25px;
	width: 80px;
	height: 140px;
	flex-direction: column;
	align-items: center;
}

.cf-brand-logo-wrapper img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
}

.ex-container {
	/*padding: 0px 30px;*/
}

.ex-tile {
	text-align: center;
}

.ex-tile img {
	max-height: 160px;
	max-width: 160px;
}

.pd-container {
}

.pd-container img {
	max-width: 70px;
	max-height: 70px;
}

.pd-card {
	height: 72px;
}

.input-box {
	width: 100%;
	background-color: #f0f0f0;
	border-radius: 10px;
	background-color: #d7f0f2;
}

.input-box input {
	border: none;
	color: gray;
	background-color: #d7f0f2;
}

.input-box input:focus {
	background-color: #d7f0f2;
}

.des-text-box-expander {
	position: absolute;
	right: 3px;
	bottom: 7px;
	width: 15px;
	height: 15px;
}

.des-text-box-expander img {
	max-width: 100%;
	max-height: 100%;
}

.stores-list {
	height: 100px;
	overflow: auto;
}
