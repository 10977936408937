html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}*/

/*select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}*/

input[type=file] {
  width: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  height: 40px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

a {
  text-decoration: none;
  color: #252525;
}

a:hover {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.no-gutter {
  padding-left: 0;
  padding-right: 0;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

/*.btn {
  text-transform: uppercase;
  margin: 35px auto;
  padding: 10px;
  border: 1px solid rgba(0,0,0,.29);
  cursor: pointer;
  background: #fff;
  font-size: 16px;
  font-weight: 700;
  width: 250px;
  position: relative;
}*/

/*.btn:hover {
  border-color: rgba(0,0,0,.5);
  text-decoration: none;
}*/

/*.btn:focus {
  outline: 0;
  font-weight: 700;
  border-width: 2px;
}*/

.btn-blue {
  background-color: #337AB7;
  font-size: 14px;
  font-weight: 700;
}

/*.btn-blue {
  text-transform: uppercase;
  margin: 10px auto;
  padding: 10px;
  border: 1px solid rgba(0,0,0,.29);
  cursor: pointer;
  background: #fff;
  font-size: 14px;
  font-weight: 700;
  width: 150px;
  position: relative;
  background-color: #337AB7;
  color: white;
  border-radius: 5px;
}*/

.container {
  /*background-color: #f1f1f1;*/
  /*padding: 0 30px 30px;
  width: 1200px;
  margin: auto;*/
}

.sidebar-container {
  /*height: calc(100vh - 65px);*/
  /*top: 65px;*/
  min-height: 100vh;
  left: 0;
  z-index: 100;
  position: -webkit-sticky;
  position: sticky;
  background-color: #365383;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  padding-top: 5px;
  color: white;
}

.sidebar-container hr {
  background-color: white;
}

.navbar {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.snapshot-tile-pill {
  padding: 5px 20px;
  background-color: white;
  font-size: 30px;
  font-weight: 600;
  margin: auto;
  border-radius: 10px;
}

.snapshot-tile-label {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  width: 100px;
}

.snapshot-tile-link {
  color: #007bff;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}

.dashboard-datepicker {
  font-size: 14px;
}

.number-bubble {
  display: -webkit-flex;
  display: flex;
  border-radius: 7px;
  font-weight: 600;
  margin: 0 auto;
}

.number-bubble span {
  margin: auto;
}

.checkmark-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  margin-right: 7px;
}

.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  /*background-color: #eee;*/
  border: 1px solid #C1C1C1;
}

.checkmark-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkmark-container input:checked ~ .checkmark {
  background-color: #0a94a1;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

.checkmark-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

ul {
  padding-left: 0;
}

li {
  list-style-type: none;
  padding: 10px;
  text-decoration: none;
}

/* From App.css */
.App {
  text-align: center;
}

.loading-logo-background {
  /*background-color: rgb(0, 0, 0, 0.1);*/
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 100;
  display: -webkit-flex;
  display: flex;
}

.loading-logo {
  -webkit-animation: App-logo-spin infinite 2s linear;
          animation: App-logo-spin infinite 2s linear;
  /*height: 40vmin;*/
  pointer-events: none;
  width: 80px;
  height: 80px;
  margin: auto;
}

.loading-logo img {
  max-height: 100%;
  max-width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/* END */

.react-datepicker-popper {
  z-index: 1000 !important;
}

/*.modal-60w {
  width: 60%;
  max-width: none !important;
}*/

.search-box {
  position: relative;
  width: 100%;
}

.search-result {
  position: absolute;
  min-width: 400px;
  min-height: 150px;
  max-height: 250px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: white;
  padding: 12px 17px;
  top: 40px;
  overflow: auto;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 7px;
  z-index: 100;
  color: #252525;
  font-weight: 100;
}

.search-result-item {
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(0,0,0,.125);
  padding-bottom: 2px;
  padding-left: 5px;
  cursor: pointer;
}

.search-result-item:hover {
  background-color: #0099a9;
  color: white;
}

.card-img-top {
  object-fit: contain;
  /*height: 15vw;*/
}

.card-img-top-container {
  position: relative;
  display: -webkit-flex;
  display: flex;
}

.card-img-top-container video {
  margin: auto;
}

.card-img-top-container div {
  position: absolute;
  top: 50%;
  color: #0099a9;
  font-size: 22px;
  cursor: pointer;
}

.card-img-top-container div:hover {
  font-size: 26px;
}

.card-img-top-leftarrow {
  left: 10px;
}

.card-img-top-rightarrow {
  right: 10px;
}

.ambassadors-filters-form {
	background-color: #1b94a0;
	padding: 20px
}

.ambassadors-filters-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.ambassadors-actions {
  position: relative;
  text-align: right;
  margin-bottom: 10px;
}

.ambassadors-actions-box {
  position: absolute;
  background-color: #F1F1F1;
  border: 1px solid #C1C1C1;
  padding: 15px;
  right: 40px;
  z-index: 100;
}

.ambassadors-actions-box div {
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  text-align: left;
  font-weight: 300;
  cursor: pointer;
}

.media-icon-container {
  display: -webkit-flex;
  display: flex;
  width: 50px;
  height: 25px;
}

.media-icon-container img {
  max-height: 100%;
  max-width: 100%;
}

.email-form-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.email-form-recipients {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-height: 200px;
  overflow: auto;
}

.email-form-container input {
  width: 100%;
  height: 25px;
  margin-bottom: 15px;
}

.email-form-container textarea {
  width: 100%;
  margin-bottom: 20px;
}

.ambassadors-map-info-window {
  display: -webkit-flex;
  display: flex;
  padding-right: 50px;
}

.ambassador-profile-img-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 30px;
}

.ambassador-profile-img {
  height: 230px;
  width: 230px;
  margin: auto;
  border-radius: 50%;
}

.ambassador-profile-picture-counter {
  font-size: 10px;
  width: 80px;
  display: -webkit-flex;
  display: flex;
  color: #343538;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  font-weight: bold;
  margin-top: 10px;
}

.ambassador-profile-info {
  width: 100%;
  font-size: 15px;
}

.ambassador-profile-right-pane {
  border-left: 1px solid #C1C1C1;
  /*padding-left: 20px;*/
}

.ambassador-profile-right-pane video {
  max-width: 100%;
  height: auto;
  margin: auto;
}

.slideshow-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.slideshow-img-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.slideshow-img-wrapper {
  width: 100%;
  height: 600px;
  display: -webkit-flex;
  display: flex;
}

.slideshow-img-wrapper img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.text-message-imgs {
  width: 50px;
  height: 50px;
  float: right;
}

.text-message-imgs img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.text-message-chat {
  height: 400px;
  width: 500px;
  border: 1px solid #C1C1C1;
  margin-bottom: 30px;
  padding: 15px;
}

.center-text {
  text-align: center;
}

.label-text {
  font-weight: 600;
  font-size: 14px;
}

p {
  font-size: 14px;
  color: #7A7A7A;
}

.fd-green {
  color: #30b1bf;
}

.semantic-modal {
  height: auto;
}

/*also semantic modal*/
.visible.transition {
  position: relative;
}

.img-fit {
  max-width: 100%;
  max-height: 100%;
}

.table-style {
	font-size: 13px!important;
	font-weight: 300!important;
	border: 1px solid #C1C1C1!important;
}

.table-header {
	border-right: none!important;
	font-weight: 600!important;
	background-color: #f1f1f1!important;
	padding: 8px!important;
	text-align: center!important;
	border-bottom: 1px solid #C1C1C1!important;
}

.table-column {
	padding: 8px!important;
	text-align: center!important;
	border-right: 1px solid #C1C1C1 !important;
}

.modal-60w {
  width: 60%;
  max-width: none !important;
}

.modal-70w {
  width: 70%;
  max-width: none !important;
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}
.filters-labels {
	font-size: 14px;
}
.brand-logo-container {
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.05;
	position: absolute;
	width: 100%;
	top: 70px;
	min-height: 100vh;
}
.actions-popover {
	position: absolute;
	right: 30px;
	top: 30px;
	z-index: 1;
}

.actions-popover p:hover {
	border-bottom: 1px solid #0699a8;
}

.photos-container {
	/*display: flex;*/
	max-height: 600px;
	overflow: auto;
}

.cf-brands-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	height: 200px;
	padding-top: 20px;
	overflow: scroll;
	overflow-x: hidden;
}

.cf-brands-container::-webkit-scrollbar {
  width: 10px;
}

.cf-brands-container::-webkit-scrollbar-track {
  background: #fce1c2;
}

.cf-brands-container::-webkit-scrollbar-thumb {
  background: #f29d35;
}

.cf-brand-logo-wrapper {
	display: -webkit-flex;
	display: flex;
	margin: 10px 25px;
	width: 80px;
	height: 140px;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
}

.cf-brand-logo-wrapper img {
	max-width: 100%;
	max-height: 100%;
	margin: auto;
}

.ex-container {
	/*padding: 0px 30px;*/
}

.ex-tile {
	text-align: center;
}

.ex-tile img {
	max-height: 160px;
	max-width: 160px;
}

.pd-container {
}

.pd-container img {
	max-width: 70px;
	max-height: 70px;
}

.pd-card {
	height: 72px;
}

.input-box {
	width: 100%;
	background-color: #f0f0f0;
	border-radius: 10px;
	background-color: #d7f0f2;
}

.input-box input {
	border: none;
	color: gray;
	background-color: #d7f0f2;
}

.input-box input:focus {
	background-color: #d7f0f2;
}

.des-text-box-expander {
	position: absolute;
	right: 3px;
	bottom: 7px;
	width: 15px;
	height: 15px;
}

.des-text-box-expander img {
	max-width: 100%;
	max-height: 100%;
}

.stores-list {
	height: 100px;
	overflow: auto;
}

