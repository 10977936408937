.table-style {
	font-size: 13px!important;
	font-weight: 300!important;
	border: 1px solid #C1C1C1!important;
}

.table-header {
	border-right: none!important;
	font-weight: 600!important;
	background-color: #f1f1f1!important;
	padding: 8px!important;
	text-align: center!important;
	border-bottom: 1px solid #C1C1C1!important;
}

.table-column {
	padding: 8px!important;
	text-align: center!important;
	border-right: 1px solid #C1C1C1 !important;
}
