.actions-popover {
	position: absolute;
	right: 30px;
	top: 30px;
	z-index: 1;
}

.actions-popover p:hover {
	border-bottom: 1px solid #0699a8;
}

.photos-container {
	/*display: flex;*/
	max-height: 600px;
	overflow: auto;
}
