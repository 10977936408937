.modal-60w {
  width: 60%;
  max-width: none !important;
}

.modal-70w {
  width: 70%;
  max-width: none !important;
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}